<template>
  <v-main>
    <v-container>
      <v-col>
        <v-row>
          <v-col v-if="$screen.width > 600" cols="1" sm="1" md="1">
            <v-avatar size="100">
              <v-img
                :src="require('../assets/profile.jpg')"
                alt="John"
                contain
                height="400px"
              />
            </v-avatar>
          </v-col>

          <v-col cols="11" sm="11" md="11">
            <v-row>
              <h4 class="ml-5 text-h4 font-weight-medium mt-5">
                {{ greeting }},
                <strong>
                  {{ this.profileName }}
                </strong>
              </h4>
            </v-row>
            <v-row>
              <h3 class="ml-5 text-h5 font-weight-medium">
                How are you, what do you want to do today ?
              </h3>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-container>

    <v-container fluid>
      <v-row dense>
        <v-col
          v-for="item in items"
          :key="item.title"
          align="center"
          justify="center"
          cols="3"
          sm="3"
          md="3"
          xs="3"
        >
          <v-spacer></v-spacer>

          <v-card>
            <v-img
              align="center"
              justify="center"
              :src="item.src"
              class="white--text align-end"
              contain
              height="100px"
              width="50px"
            >
              <!-- <v-card-title v-text="item.title"></v-card-title> -->
            </v-img>

            <v-card-actions v-if="$screen.width > 600">
              <v-card-title v-text="item.title"></v-card-title>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-col>
        <v-row>
          <v-col>
            <v-col>
              <v-row>
                <h1 class="text-center">
                  <strong> Activity Chart </strong>
                </h1>
              </v-row>
              <v-row>
                <h4>Showing activity chart for 6 months ago</h4>
              </v-row>
            </v-col>
            <Bar
              :chart-options="chartOptions"
              :chart-data="chartDataaa"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
            />
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                <v-col>
                  <v-row>
                    <h1 class="text-center">
                      <strong> Admin Activity </strong>
                    </h1>
                  </v-row>
                  <v-row>
                    <h4>Last Activity</h4>
                  </v-row>
                </v-col>
              </v-card-title>
              <v-card-text class="py-0">
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="item in activity.slice(0, 10)"
                    :key="item.waktu"
                    :color="
                      item.keterangan == 'Created' ? 'teal lighten-3' : 'pink'
                    "
                    small
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <v-row>
                          <strong>{{
                            moment(item.waktu).format("ddd, MMM D YYYY")
                          }}</strong>
                        </v-row>
                        <v-row>
                          <strong>{{
                            moment(item.waktu).format("HH:mm")
                          }}</strong>
                        </v-row>
                      </v-col>
                      <v-col>
                        <strong>
                          {{ item.tipe }} was {{ item.keterangan }}
                        </strong>
                        <div
                          v-if="item.keterangan === 'Created'"
                          class="text-caption"
                        >
                          By : {{ item.createBy }}
                        </div>
                        <div
                          v-else-if="item.keterangan === 'Updated'"
                          class="text-caption"
                        >
                          By : {{ item.updateBy }}
                        </div>
                        <div v-else class="text-caption">By : UnKnown</div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
import { getAuth } from "firebase/auth";
const auth = getAuth();
const user = auth.currentUser;
// import BarChart from "./BarChart.vue";

import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

import {
  collection,
  // getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import moment from "moment";

const dbNews = collection(getFirestore(), "news");
const dbPackage = collection(getFirestore(), "package_list");
const dbPromo = collection(getFirestore(), "promotion");

// import { getStorage } from "firebase/storage";
export default {
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      chartData: {
        title: "Ativity Chart",
        labels: [
          moment().subtract(5, "months").format("MMMM"),
          moment().subtract(4, "months").format("MMMM"),
          moment().subtract(3, "months").format("MMMM"),
          moment().subtract(2, "months").format("MMMM"),
          moment().subtract(1, "months").format("MMMM"),
          moment().format("MMMM"),
        ],
        datasets: [
          {
            label: "All Data Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "News Activity",
            backgroundColor: "#EF9A9A",
            data: [],
          },
          {
            label: "Promo Activity",
            backgroundColor: "#CE93D8",
            data: [],
          },
          {
            label: "Internet Activity",
            backgroundColor: "#64B5F6",
            data: [],
          },
          {
            label: "CCTV Activity",
            backgroundColor: "#80CBC4",
            data: [],
          },
          {
            label: "TV Activity",
            backgroundColor: "#FF8A65",
            data: [],
          },
        ],
      },
      chartData2: {
        labels: [
          moment().subtract(5, "months").format("MMMM"),
          moment().subtract(4, "months").format("MMMM"),
          moment().subtract(3, "months").format("MMMM"),
          moment().subtract(2, "months").format("MMMM"),
          moment().subtract(1, "months").format("MMMM"),
          moment().format("MMMM"),
        ],
        datasets: [
          {
            label: "All Data Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "News Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "Promo Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "Internet Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "CCTV Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "TV Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "All Data Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
          {
            label: "All Data Activity",
            backgroundColor: "#FB9300",
            data: [],
          },
        ],
      },

      chartOptions: {
        responsive: true,
      },
      profileName: "",
      items: [
        // { title: "Dashboard", to: "/", src: "../assets/dashboard.jpg" },
        {
          title: "News",
          to: "news",
          src: "https://cdn-icons-png.flaticon.com/512/4097/4097354.png",
        },
        {
          title: "Promo",
          to: "promo",
          src: "https://icon-library.com/images/promotion-icon-png/promotion-icon-png-11.jpg",
        },
        {
          title: "List Paket",
          to: "package",
          src: "https://icon-library.com/images/bundle-icon/bundle-icon-18.jpg",
        },
        {
          title: "List Point",
          to: "point",
          src: "http://cdn.onlinewebfonts.com/svg/img_309370.png",
        },
        // { title: "List Admin", to: "admin" },
      ],
      greeting:
        new Date().getHours() < 12
          ? "Good Morning"
          : new Date().getHours() < 18
          ? "Good Afternoon"
          : "Good Evening",

      news: [],
      promo: [],
      paket: [],
      activity: [],
    };
  },

  methods: {
    readData() {
      // const data = getStorage();
      // console.log("getstorage ", data);
      // console.log("test blm");
    },

    realTImeReadDataNews() {
      onSnapshot(dbNews, (snapshot) => {
        let news = [];

        snapshot.docs.forEach((doc) => {
          if (
            doc.data().updateData.toDate().toString() ==
            doc.data().timestamp.toDate().toString()
          ) {
            console.log("sama");
            news.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "News",
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "News",
            });
          } else {
            news.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "News",
            });
            news.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Updated",
              waktu: doc.data().updateData.toDate(),
              tipe: "News",
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "News",
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Updated",
              waktu: doc.data().updateData.toDate(),
              tipe: "News",
            });
          }
        });

        this.news = news.sort((a, b) => {
          return b.waktu - a.waktu;
        });

        this.activity = this.activity.sort((a, b) => {
          return b.waktu - a.waktu;
        });

        console.log("activity news", this.activity);

        console.log("news", this.news.length);
      });
    },

    realTImeReadDataPromo() {
      onSnapshot(dbPromo, (snapshot) => {
        let promo = [];
        // let temp = [];

        snapshot.docs.forEach((doc) => {
          // console.log(doc.data().updateData.toDate());
          // console.log(doc.data().timestamp.toDate());

          if (
            doc.data().updateData.toDate().toString() ==
            doc.data().timestamp.toDate().toString()
          ) {
            console.log("sama");
            promo.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Promo",
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Promo",
            });
          } else {
            promo.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Promo",
            });
            promo.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Updated",
              waktu: doc.data().updateData.toDate(),
              tipe: "Promo",
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Promo",
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Updated",
              waktu: doc.data().updateData.toDate(),
              tipe: "Promo",
            });
          }
        });

        this.promo = promo.sort((a, b) => {
          return b.waktu - a.waktu;
        });

        this.activity = this.activity.sort((a, b) => {
          return b.waktu - a.waktu;
        });

        console.log("activity promo", this.activity);
        console.log("promo", this.promo);
      });
    },

    realTImeReadDataPackage() {
      onSnapshot(dbPackage, (snapshot) => {
        let paket = [];
        var temp = "";

        snapshot.docs.forEach((doc) => {
          if (doc.data().package_name == "internet") {
            temp = "Internet";
          } else if (doc.data().package_name == "tv") {
            temp = "TV";
          } else if (doc.data().package_name == "cctv") {
            temp = "CCTV";
          }

          if (
            doc.data().updateData.toDate().toString() ==
            doc.data().timestamp.toDate().toString()
          ) {
            paket.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Package " + temp,
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Package " + temp,
            });
          } else {
            paket.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Package " + temp,
            });
            paket.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Updated",
              waktu: doc.data().updateData.toDate(),
              tipe: "Package " + temp,
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Created",
              waktu: doc.data().timestamp.toDate(),
              tipe: "Package " + temp,
            });
            this.activity.push({
              ...doc.data(),
              id: doc.id,
              keterangan: "Updated",
              waktu: doc.data().updateData.toDate(),
              tipe: "Package " + temp,
            });
          }
        });

        this.paket = paket.sort((a, b) => {
          return b.waktu - a.waktu;
        });
        this.activity = this.activity.sort((a, b) => {
          return b.waktu - a.waktu;
        });

        var datatemp = 0;
        var datatemp1 = 0;
        var datatemp2 = 0;
        var datatemp3 = 0;
        var datatemp4 = 0;
        var datatemp5 = 0;

        var datatempNews = 0;
        var datatempNews1 = 0;
        var datatempNews2 = 0;
        var datatempNews3 = 0;
        var datatempNews4 = 0;
        var datatempNews5 = 0;

        var datatempPromo = 0;
        var datatempPromo1 = 0;
        var datatempPromo2 = 0;
        var datatempPromo3 = 0;
        var datatempPromo4 = 0;
        var datatempPromo5 = 0;

        var datatempInternet = 0;
        var datatempInternet1 = 0;
        var datatempInternet2 = 0;
        var datatempInternet3 = 0;
        var datatempInternet4 = 0;
        var datatempInternet5 = 0;

        var datatempCCTV = 0;
        var datatempCCTV1 = 0;
        var datatempCCTV2 = 0;
        var datatempCCTV3 = 0;
        var datatempCCTV4 = 0;
        var datatempCCTV5 = 0;

        var datatempTV = 0;
        var datatempTV1 = 0;
        var datatempTV2 = 0;
        var datatempTV3 = 0;
        var datatempTV4 = 0;
        var datatempTV5 = 0;

        var month = moment().format("M");
        var year = moment().format("YYYY");
        var testing = 0;

        this.activity.forEach((item) => {
          if (item.keterangan == "Created") {
            if (
              moment(item.timestamp.toDate()).format("YYYY") == year &&
              moment(item.timestamp.toDate()).format("M") == month
            ) {
              datatemp++;
              console.log("data temp juli", datatemp);
              if (item.tipe == "News") {
                datatempNews++;
              } else if (item.tipe == "Promo") {
                datatempPromo++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV++;
              } else if (item.tipe == "Package TV") {
                datatempTV++;
              }
            } else if (
              moment(item.timestamp.toDate()).format("YYYY") == year &&
              moment(item.timestamp.toDate()).format("M") == month - 1
            ) {
              datatemp1++;
              if (item.tipe == "News") {
                datatempNews1++;
              } else if (item.tipe == "Promo") {
                datatempPromo1++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet1++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV1++;
              } else if (item.tipe == "Package TV") {
                datatempTV1++;
              }
            } else if (
              moment(item.timestamp.toDate()).format("YYYY") == year &&
              moment(item.timestamp.toDate()).format("M") == month - 2
            ) {
              datatemp2++;
              if (item.tipe == "News") {
                datatempNews2++;
              } else if (item.tipe == "Promo") {
                datatempPromo2++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet2++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV2++;
              } else if (item.tipe == "Package TV") {
                datatempTV2++;
              }
            } else if (
              moment(item.timestamp.toDate()).format("YYYY") == year &&
              moment(item.timestamp.toDate()).format("M") == month - 3
            ) {
              datatemp3++;
              if (item.tipe == "News") {
                datatempNews3++;
              } else if (item.tipe == "Promo") {
                datatempPromo3++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet3++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV3++;
              } else if (item.tipe == "Package TV") {
                datatempTV3++;
              }
            } else if (
              moment(item.timestamp.toDate()).format("YYYY") == year &&
              moment(item.timestamp.toDate()).format("M") == month - 4
            ) {
              datatemp4++;
              if (item.tipe == "News") {
                datatempNews4++;
              } else if (item.tipe == "Promo") {
                datatempPromo4++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet4++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV4++;
              } else if (item.tipe == "Package TV") {
                datatempTV4++;
              }
            } else if (
              moment(item.timestamp.toDate()).format("YYYY") == year &&
              moment(item.timestamp.toDate()).format("M") == month - 5
            ) {
              datatemp5++;
              if (item.tipe == "News") {
                datatempNews5++;
              } else if (item.tipe == "Promo") {
                datatempPromo5++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet5++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV5++;
              } else if (item.tipe == "Package TV") {
                datatempTV5++;
              }
            }
          } else if (item.keterangan == "Updated") {
            if (
              moment(item.updateData.toDate()).format("YYYY") == year &&
              moment(item.updateData.toDate()).format("M") == month
            ) {
              datatemp++;
              if (item.tipe == "News") {
                datatempNews++;
              } else if (item.tipe == "Promo") {
                datatempPromo++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV++;
              } else if (item.tipe == "Package TV") {
                datatempTV++;
              }
            } else if (
              moment(item.updateData.toDate()).format("YYYY") == year &&
              moment(item.updateData.toDate()).format("M") == month - 1
            ) {
              datatemp1++;
              if (item.tipe == "News") {
                datatempNews1++;
              } else if (item.tipe == "Promo") {
                datatempPromo1++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet1++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV1++;
              } else if (item.tipe == "Package TV") {
                datatempTV1++;
              }
            } else if (
              moment(item.updateData.toDate()).format("YYYY") == year &&
              moment(item.updateData.toDate()).format("M") == month - 2
            ) {
              datatemp2++;
              if (item.tipe == "News") {
                datatempNews2++;
              } else if (item.tipe == "Promo") {
                datatempPromo2++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet2++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV2++;
              } else if (item.tipe == "Package TV") {
                datatempTV2++;
              }
            } else if (
              moment(item.updateData.toDate()).format("YYYY") == year &&
              moment(item.updateData.toDate()).format("M") == month - 3
            ) {
              datatemp3++;
              if (item.tipe == "News") {
                datatempNews3++;
              } else if (item.tipe == "Promo") {
                datatempPromo3++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet3++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV3++;
              } else if (item.tipe == "Package TV") {
                datatempTV3++;
              }
            } else if (
              moment(item.updateData.toDate()).format("YYYY") == year &&
              moment(item.updateData.toDate()).format("M") == month - 4
            ) {
              datatemp4++;
              if (item.tipe == "News") {
                datatempNews4++;
              } else if (item.tipe == "Promo") {
                datatempPromo4++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet4++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV4++;
              } else if (item.tipe == "Package TV") {
                datatempTV4++;
              }
            } else if (
              moment(item.updateData.toDate()).format("YYYY") == year &&
              moment(item.updateData.toDate()).format("M") == month - 5
            ) {
              datatemp5++;
              if (item.tipe == "News") {
                datatempNews5++;
              } else if (item.tipe == "Promo") {
                datatempPromo5++;
              } else if (item.tipe == "Package Internet") {
                datatempInternet5++;
              } else if (item.tipe == "Package CCTV") {
                datatempCCTV5++;
              } else if (item.tipe == "Package TV") {
                datatempTV5++;
              }
            }
          }
        });

        console.log(testing);

        this.chartData.datasets[0].data.push(datatemp5);
        this.chartData.datasets[0].data.push(datatemp4);
        this.chartData.datasets[0].data.push(datatemp3);
        this.chartData.datasets[0].data.push(datatemp2);
        this.chartData.datasets[0].data.push(datatemp1);
        this.chartData.datasets[0].data.push(datatemp);

        this.chartData.datasets[1].data.push(datatempNews5);
        this.chartData.datasets[1].data.push(datatempNews4);
        this.chartData.datasets[1].data.push(datatempNews3);
        this.chartData.datasets[1].data.push(datatempNews2);
        this.chartData.datasets[1].data.push(datatempNews1);
        this.chartData.datasets[1].data.push(datatempNews);

        this.chartData.datasets[2].data.push(datatempPromo5);
        this.chartData.datasets[2].data.push(datatempPromo4);
        this.chartData.datasets[2].data.push(datatempPromo3);
        this.chartData.datasets[2].data.push(datatempPromo2);
        this.chartData.datasets[2].data.push(datatempPromo1);
        this.chartData.datasets[2].data.push(datatempPromo);

        this.chartData.datasets[3].data.push(datatempInternet5);
        this.chartData.datasets[3].data.push(datatempInternet4);
        this.chartData.datasets[3].data.push(datatempInternet3);
        this.chartData.datasets[3].data.push(datatempInternet2);
        this.chartData.datasets[3].data.push(datatempInternet1);
        this.chartData.datasets[3].data.push(datatempInternet);

        this.chartData.datasets[4].data.push(datatempCCTV5);
        this.chartData.datasets[4].data.push(datatempCCTV4);
        this.chartData.datasets[4].data.push(datatempCCTV3);
        this.chartData.datasets[4].data.push(datatempCCTV2);
        this.chartData.datasets[4].data.push(datatempCCTV1);
        this.chartData.datasets[4].data.push(datatempCCTV);

        this.chartData.datasets[5].data.push(datatempTV5);
        this.chartData.datasets[5].data.push(datatempTV4);
        this.chartData.datasets[5].data.push(datatempTV3);
        this.chartData.datasets[5].data.push(datatempTV2);
        this.chartData.datasets[5].data.push(datatempTV1);
        this.chartData.datasets[5].data.push(datatempTV);

        // this.$refs.bar.updateChart();
        console.log("chartdata", this.chartData.datasets);
        console.log("activity paket", this.activity);
        console.log("paket", this.paket);
      });
    },

    readAllData() {
      this.realTImeReadDataNews();
      this.realTImeReadDataPromo();
      this.realTImeReadDataPackage();
    },

    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.png$/);
      return images("./" + pet + ".jpg");
    },
  },
  computed: {
    chartDataaa() {
      return this.chartData; /* mutable chart data */
    },
  },

  mounted() {
    this.readData;
    // this.realTImeReadDataNews();
    this.readAllData();
    console.log("curentsuernew", user);
    console.log("curentsuernew", this.chartData.datasets[0].data);
    this.profileName = user.email.match(/^([^@]*)@/)[1];
  },
};
</script>

<style></style>
